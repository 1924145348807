<template>
     <div>
          <modal :name="modal_name" class="final-modal increase-width-popups-variants-addons popup-modal-all-width-height" transition="nice-modal-fade">
               <div class="v-modal-content">
                    <div class="v-modal-header">
                         <span class="v-modal-dialog-title v-modal-title text-white">Product Info</span>
                         <span @click="hideProductInfoPopup()">
                         <i class="icon icon-close pointer text-white"></i>
                         </span>
                    </div>
                    <div class="general-section mod-popup" style="padding: 15px 0px;">
                         <div class="v-modal-body">

                         </div>
                    </div>
               </div>
          </modal>
     </div>
</template>

<script>

export default({
     props: ['modal_name'],
     data(){
          return{

          }
     },
     methods:{
          hideProductInfoPopup(){
               this.$emit('hide-product-info');
          }
     }
})

</script>

<style scoped>

</style>